<template>
  <div class="container">
    <div class="logoContainer">
      <img class="p-4 logo" alt="Star Wars logo" src="../assets/logo_star-wars.png" />
    </div>
    <BookCard v-if="books" :books="books" />
    <BookCardSkeleton v-else />
  </div>
</template>

<script setup>
import BookCard from './BookCard.vue';
import BookCardSkeleton from './BookCardSkeleton.vue';
import romans from "../data/romans.json";
const books = romans.filter(book => book.bAcquired).sort((a, b) => b.nNumber - a.nNumber)
</script>

<style scoped>
.logoContainer {
  display: flex;
  justify-content: center;
}

.logo {
  width: 33vw;
}

.text {
  color: red;
  font-size: 48px;
  top: 20px;
  left: 20px;
}

@media (max-width: 720px) {
  .logo {
    width: 95vw;
    max-width: 95vw;
  }
}
</style>
