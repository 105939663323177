<template>
  <div class="card p-2 m-2 bg-card" v-for="(book, index) in props.books" :key="index">
    <div class="mb-2 first-line">
      <img class="thumbnail" v-bind:src="'/covers/star_wars/' + book.sCover" />
      <p class="number">{{ book.nNumber }}</p>
    </div>
    <div><span class="title">{{ book.sTitle }}</span>
      <br />
      <span>ISBN : {{ formatISBN(book.sIsbn) }}</span>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  books: Array,
});

const formatISBN = (props) => {
  const isbn = props.toString();
  if (!isbn) return "Pas de numéro";
  let splitting = [3, 4, 7, 12];
  const prefix = isbn.substring(0, splitting[0]);
  const group = isbn.substring(splitting[0], splitting[1]);
  const publisher = isbn.substring(splitting[1], splitting[2]);
  const publication = isbn.substring(splitting[2], splitting[3]);
  const control = isbn.substring(splitting[3], 13);
  return prefix + "-" + group + "-" + publisher + "-" + publication + "-" + control;
}
</script>

<style scoped>
.number {
  font-size: 120px;
  font-weight: 800;
  margin-bottom: 0;
  line-height: 140px;
}

.title {
  font-size: 18px;
  font-weight: 700;
}

.thumbnail {
  width: 100px
}

.first-line {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.bg-card {
  background-color: whitesmoke;
}
</style>